<template>
  <main>
    <a-table
      style="background-color: white"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :row-key="(record) => record.id"
    >
      <template v-slot:created="text">
        {{ parseDateToRuFormat(text) }}
      </template>
      <template v-slot:vote="text">
        <a-rate :default-value="text" disabled />
      </template>
      <template v-slot:action="text">
        <a-tooltip placement="top" title="Отклонить">
          <a-button @click="showConfirm(text.id)">
            <a-icon type="close" theme="twoTone" />
            <a-icon type="delete" />
          </a-button>
        </a-tooltip>
      </template>
      <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
        {{ record.health_facility.name }}
      </p>
    </a-table>
  </main>
</template>

<script>
import axios from "axios";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 70,
  },
  {
    title: "Автор",
    dataIndex: "user.name",
    key: "user.name",
  },
  {
    title: "Дата",
    dataIndex: "created",
    key: "created",
    scopedSlots: { customRender: "created" },
    width: 130,
  },
  {
    title: "Рейтинг",
    dataIndex: "vote",
    key: "vote",
    scopedSlots: { customRender: "vote" },
    width: 170,
  },
  {
    title: "Текст",
    dataIndex: "text",
    key: "text",
  },
  {
    title: "Действия",
    scopedSlots: { customRender: "action" },
    width: 100,
  },
];
export default {
  name: "ReviewsModeration",
  created() {
    this.getReviewsList();
  },
  data() {
    return {
      columns,
      data: [],
      pagination: {},
      loading: false,
      processing: false,
      isDataLoaded: true, //@@@ spinnder, make it false before api calls
      reviewsList: [],
      currentPage: 1,
      pageNumber: 1,
    };
  },
  methods: {
    getReviewsList(pageNumber) {
      this.loading = true;
      axios
        .get("/cp/feedback/", {
          params: {
            page: pageNumber,
            status: 1,
          },
        })
        .then((response) => {
          this.data = response.data.data;
          this.currentPage = response.data.meta.pagination.page;
          this.totalPages = response.data.meta.pagination.total;
          this.hasNextPage = response.data.meta.pagination.has_next;
          this.hasPrevPage = response.data.meta.pagination.has_prev;
          this.errorMessage = null;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    declineReview(review_id) {
      // text = new text for review
      // @@@ patched text
      // status ?
      let data = {
        status: 2,
      };
      axios
        .patch(`/cp/feedback/${review_id}/`, data)
        .then((response) => {
          console.log(response);
          this.getReviewsList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showConfirm(id) {
      const callback = this.declineReview;
      this.$confirm({
        title: "Вы уверены что хотите удалить этот отзыв?",
        // content: () => <div style="color:red;">Some descriptions</div>,
        okText: "Да",
        cancelText: "Нет",
        onOk() {
          callback(id);
        },
        onCancel() {},
        class: "ConfirmDeclineReview" + id.toString(),
      });
    },
    parseDateToRuFormat: function (date) {
      let my_date = new Date(date * 1000).getTimezoneOffset() * -60;
      let gooddate = (date + my_date) * 1000;
      return new Intl.DateTimeFormat("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(gooddate);
    },
  },
};
</script>

<style scoped></style>
